<template>
  <!-- preview modal -->
  <b-modal
    id="demo-video-row-modal"
    ref="demotemModal"
    :title="
      itemContent && itemContent.videoTitle
        ? itemContent.videoTitle
        : 'Generated demo Ad'
    "
    size="xl"
    body-bg-variant="dark"
    header-class="font-primary"
    header-bg-variant="dark"
    header-text-variant="white"
    header-border-variant="dark"
    hide-footer
    centered
    @hide="closeModal()"
  >
    <!-- custom close icon -->
    <template v-slot:modal-header-close>
      <font-awesome-icon
        icon="times"
        class="text-white"
        @click="closeModal()"
        aria-label="Close Modal"
      ></font-awesome-icon>
    </template>
    <b-row
      class="d-flex flex-row align-items-start justify-content-start w-100"
      no-gutters
      v-if="itemContent && itemContent.videoURL"
    >
      <video
        width="100%"
        height="auto"
        autoplay="autoplay" controls="controls" 
        :src="itemContent.videoURL"
      ></video>
    </b-row>
  </b-modal>
</template>

<script>
// services

export default {
  name: "PreviewModal",
  props: {
    isShow: {
      default: false,
      type: Boolean,
    },
    itemContent: {
      default: null,
      type: Object,
    },
  },
  methods: {
    closeModal() {
      this.$emit("onClose");
    },
  },
};
</script>