<template>
  <HomeLayout>
    <b-container
      class="d-flex flex-column align-items-start justify-content-start px-0"
    >
      <!-- table container row -->
      <b-row
        class="
          d-flex
          flex-row
          align-items-stretch
          justify-content-center
          w-100
          card-row
        "
        no-gutters
      >
        <b-card
          no-body
          class="overflow-hidden shadow-sm border-0 w-100 card-container"
        >
          <!-- Add Target Video form -->
          <form
            id="new-target-video-form"
            class="
              d-flex
              flex-row flex-wrap
              align-items-start
              justify-content-start
              mx-0
              px-2
              py-0
              h-100
            "
          >
            <b-col
              cols="12"
              lg="6"
              class="d-flex flex-column align-items-start justify-content-start"
            >
              <h6
                class="
                  text-left text-dark
                  font-weight-semi-bold font-primary
                  mb-4
                  mt-4
                "
              >
                Create a demo video
              </h6>
              <!-- full row input (First name) -->
              <b-row
                class="
                  d-flex
                  flex-row
                  align-items-start
                  justify-content-start
                  w-100
                "
                no-gutters
              >
                <b-col cols="12">
                  <FormInput
                    groupId="video-title-group"
                    id="video-title-group-input"
                    class="text-prime-gray"
                    :state="firstNameError.status"
                    :invalidFeedback="`${firstNameError.message}`"
                    label="First Name"
                    v-model="firstName"
                    isRequired
                    trim
                    form="new-target-video-form"
                    type="text"
                  ></FormInput>
                </b-col>
              </b-row>
              <!-- full row input (last name) -->
              <b-row
                class="
                  d-flex
                  flex-row
                  align-items-start
                  justify-content-start
                  w-100
                "
                no-gutters
              >
                <b-col cols="12">
                  <FormInput
                    groupId="video-title-group"
                    id="video-title-group-input"
                    class="text-prime-gray"
                    :state="lastNameError.status"
                    :invalidFeedback="`${lastNameError.message}`"
                    label="Last Name"
                    v-model="lastName"
                    isRequired
                    trim
                    form="new-target-video-form"
                    type="text"
                  ></FormInput>
                </b-col>
              </b-row>
              <!-- full row input (billing address) -->
              <!-- <b-row
                class="
                  d-flex
                  flex-row
                  align-items-start
                  justify-content-start
                  w-100
                "
                no-gutters
              >
                <b-col cols="12">
                  <FormInput
                    groupId="video-title-group"
                    id="video-title-group-input"
                    class="text-prime-gray"
                    :state="billingAddressError.status"
                    :invalidFeedback="`${billingAddressError.message}`"
                    label="Billing Address"
                    v-model="billingAddress"
                    isRequired
                    trim
                    form="new-target-video-form"
                    type="text"
                  ></FormInput>
                </b-col>
              </b-row> -->
              <!-- full row input (city) -->
              <b-row
                class="
                  d-flex
                  flex-row
                  align-items-start
                  justify-content-start
                  w-100
                "
                no-gutters
              >
                <b-col cols="12">
                  <FormInput
                    groupId="video-title-group"
                    id="video-title-group-input"
                    class="text-prime-gray"
                    :state="cityError.status"
                    :invalidFeedback="`${cityError.message}`"
                    label="City"
                    v-model="city"
                    isRequired
                    trim
                    form="new-target-video-form"
                    type="text"
                  ></FormInput>
                </b-col>
              </b-row>
              <!-- full row input (state) -->
              <b-row
                class="
                  d-flex
                  flex-row
                  align-items-start
                  justify-content-start
                  w-100
                "
                no-gutters
              >
                <b-col cols="12">
                  <FormSelect
                    groupId="video-title-group"
                    id="video-title-group-input"
                    class="text-prime-gray"
                    :state="stateError.status"
                    :invalidFeedback="`${stateError.message}`"
                    label="State"
                    v-model="state"
                    isRequired
                    trim
                    :options="stateList"
                    form="new-target-video-form"
                    type="text"
                  ></FormSelect>
                </b-col>
              </b-row>
              <!-- full row input (zipcode) -->
              <!-- <b-row
                class="
                  d-flex
                  flex-row
                  align-items-start
                  justify-content-start
                  w-100
                "
                no-gutters
              >
                <b-col cols="12">
                  <FormInput
                    groupId="video-title-group"
                    id="video-title-group-input"
                    class="text-prime-gray"
                    :state="zipcodeError.status"
                    :invalidFeedback="`${zipcodeError.message}`"
                    label="Zip Code"
                    v-model="zipcode"
                    isRequired
                    trim
                    form="new-target-video-form"
                    type="number"
                  ></FormInput>
                </b-col>
              </b-row> -->
              <b-col
                cols="12"
                class="
                  d-flex
                  flex-column
                  align-items-center
                  justify-content-center
                  align-self-center
                  mb-2
                "
              >
                <!-- form action -->
                <b-row
                  class="
                    d-flex
                    flex-column-reverse flex-md-row
                    align-items-center
                    justify-content-center
                    mt-3
                    w-100
                  "
                  no-gutters
                >
                  <!-- save and exit button -->
                  <b-col
                    class="
                      d-flex
                      flex-column
                      align-items-center
                      justify-content-center
                      pl-0 pl-md-2
                      py-2
                    "
                    cols="12"
                    md="4"
                    lg="3"
                    xl="2"
                  >
                    <FormButton
                      id="save-exit-btn"
                      variant="primary"
                      isBlock
                      :disabled="isAPILoading"
                      :isLoading="isAPILoading"
                      class="text-white"
                      type="submit"
                      :isDisabled="videoGenerating"
                      @click.native="videoCreateFn()"
                      >Generate Demo</FormButton
                    >
                  </b-col>
                </b-row>
              </b-col>
            </b-col>
            <b-col
              cols="12"
              lg="6"
              class="d-flex flex-column align-items-start justify-content-start"
            >
              <h6
                class="
                  text-left text-dark
                  font-weight-semi-bold font-primary
                  mb-4
                  mt-4
                "
              >
                Video Preview
              </h6>
              <!-- full row image uploader -->
              <b-row
                class="
                  d-flex
                  flex-row
                  align-items-start
                  justify-content-start
                  w-100
                  video-preview-row
                "
                no-gutters
              >
                <b-col cols="12" v-if="!isVideoReady && videoGenerating">
                  <b-row
                    class="
                      d-flex
                      w-100
                      mt-5
                      mb-5
                      align-items-center
                      justify-content-center
                    "
                  >
                    <b-spinner
                      variant="primary"
                      style="width: 5rem; height: 5rem"
                      class="spinner"
                    ></b-spinner>
                  </b-row>
                  <h6 class="headings">
                    Generating your demo ad. This might take a moment.
                  </h6>
                </b-col>
                <b-col cols="12" v-else>
                  <b-row
                    class="
                      d-flex
                      flex-row
                      align-items-start
                      justify-content-start
                      position-relative
                      video-container-row
                    "
                    no-gutters
                  >
                    <!-- <img src="" class="video-preview-image" />
                    <b-button
                      v-b-tooltip.hover
                      title="Preview Video"
                      size="sm"
                      class="
                        d-flex
                        flex-row
                        align-items-center
                        justify-content-center
                        video-preview-button
                      "
                      pill
                      @click="openPreviewVideoModal(demoVideo, $event.target)"
                    >
                      <font-awesome-icon
                        icon="play"
                        aria-label="Preview Video"
                        class="play-icon"
                      ></font-awesome-icon>
                    </b-button> -->
                    <video
                      ref="videoRef"
                      id="video-container"
                      width="100%"
                      controls
                      :class="!demoVideo ? 'd-none' : ''"
                      :src="demoVideo"
                    ></video>
                    <template v-if="!demoVideo">
                      <div
                        class="
                          d-flex
                          flex-row
                          align-items-center
                          justify-content-center
                          border border-ghost-white
                          w-100
                          video-placeholder
                        "
                      >
                        <h6 class="text-center py-3 mx-auto">
                          <span class="d-block text-muted mt-2"
                            >(Preview Video)</span
                          >
                        </h6>
                      </div>
                    </template>
                  </b-row>
                </b-col>
              </b-row>
              <b-row
                class="
                  d-flex
                  align-items-center
                  justify-content-start
                  px-0
                  mt-4
                "
                no-gutters
              >
                <b-col cols="12" lg="4">
                  <h6
                    class="
                      text-left text-dark
                      font-weight-semi-bold font-primary
                      mt-3
                      mb-3
                    "
                  >
                    Video Preview Link
                  </h6>
                </b-col>
                <b-col cols="12" lg="12">
                  <FormInputGroup
                    id="video-preview-link-input"
                    class="video-preview-form-group"
                    v-model="getVideoPreviewLink"
                    isAppend
                    type="text"
                  >
                    <template v-slot:append>
                      <b-button
                        class="px-2 px-md-3 py-0 bg-light append-button"
                        variant="transparent"
                        @click="copyLink('video-preview-link-input')"
                      >
                        <span>Copy Link</span>
                        <font-awesome-icon
                          class="text-prime-dark fa-lg ml-1"
                          icon="link"
                        ></font-awesome-icon>
                      </b-button>
                    </template>
                  </FormInputGroup>
                </b-col>
              </b-row>
            </b-col>
          </form>
        </b-card>
      </b-row>
      <!-- target video row -->
      <b-row
        class="
          d-flex
          flex-row
          align-items-stretch
          justify-content-center
          w-100
          video-card-row
        "
        no-gutters
      >
        <b-card
          class="
            overflow-hidden
            border border-light
            shadow-sm
            w-100
            video-card-container
          "
        >
          <b-row class="px-0 py-0 body-container" no-gutters>
            <b-col class="px-0">
              <h6
                class="
                  text-left text-dark text-large
                  font-weight-semi-bold font-primary
                  mb-4
                  mt-2
                "
              >
                Demo Videos
              </h6>
              <b-table
                :bordered="false"
                :borderless="true"
                :hover="true"
                :striped="true"
                selectable
                select-mode="single"
                small
                class="table-element"
                thead-class="table-header font-primary"
                tbody-class="table-body"
                :fields="HeaderFields"
                :items="demoVideoList"
                show-empty
                :busy="isLoading"
                :current-page="currentPage"
                :per-page="perPage"
              >
                <!-- index no column -->
                <!-- table busy state -->
                <template #table-busy>
                  <div class="text-center text-muted my-3">
                    <b-spinner class="align-middle"></b-spinner>
                    <span class="text-left mx-1">Loading...</span>
                  </div>
                </template>
                <!-- table empty state -->
                <template #empty="scope">
                  <b-row
                    class="
                      d-flex
                      flex-row
                      align-items-center
                      justfiy-content-center
                      py-5
                    "
                    no-gutters
                  >
                    <h6 class="text-center mb-0 mx-auto my-5">
                      {{ scope.emptyText }}
                    </h6>
                  </b-row>
                </template>
                <template v-slot:cell(indexNo)="data">
                  <b-col
                    class="
                      d-flex
                      flex-column
                      align-items-start
                      justify-content-center
                      px-0
                    "
                  >
                    <span class="hits-count-text text-dark">{{
                      data.value
                    }}</span>
                  </b-col>
                </template>
                <!-- donor column -->
                <template v-slot:cell(donor)="data">
                  <b-col
                    class="
                      d-flex
                      flex-column
                      align-items-start
                      justify-content-center
                      px-0
                    "
                  >
                    <span class="hits-count-text text-dark">{{
                      data.item.donor.name
                    }}</span>
                  </b-col>
                </template>
                <!-- video link column -->
                <template v-slot:cell(downloadLink)="data">
                  <b-col
                    class="
                      d-flex
                      flex-column
                      align-items-start
                      justify-content-center
                      px-0
                    "
                  >
                    <div
                      class="
                        d-flex
                        flex-row
                        align-items-center
                        justify-content-center
                        text-dark
                        main-text-container
                      "
                    >
                      <span class="ml-3 text-truncate">
                        {{ data.item.downloadLink.value }}
                      </span>
                    </div>
                  </b-col>
                </template>

                <!-- Video Preview Link -->
                <template v-slot:cell(createdAt)="data">
                  <b-col
                    class="
                      d-flex
                      flex-column
                      align-items-start
                      justify-content-center
                      px-0
                    "
                  >
                    <div
                      class="
                        d-flex
                        flex-row
                        align-items-center
                        justify-content-center
                        text-dark
                        main-text-container
                      "
                    >
                      <span class="text-truncate">
                        {{ data.item.createdAt.value }}
                      </span>
                    </div>
                  </b-col>
                </template>
                <!-- Video Preview Link -->
                <template v-slot:cell(previewLink)="data">
                  <b-col
                    class="
                      d-flex
                      flex-column
                      align-items-start
                      justify-content-center
                      px-0
                    "
                  >
                    <!-- <div
                      class="
                        d-flex
                        flex-row
                        align-items-center
                        justify-content-center
                        text-dark
                        main-text-container
                      "
                    > -->
                    <span class="">
                      <a
                        id="test"
                        :href="data.item.previewLink.value"
                        target="_blank"
                        rel="noopener noreferrer"
                        >{{ data.item.previewLink.value }}</a
                      >
                    </span>
                    <!-- </div> -->
                  </b-col>
                </template>
                <template v-slot:cell(linkIcon)="data">
                  <b-col
                    class="
                      d-flex
                      flex-column
                      align-items-start
                      justify-content-center
                      px-0
                    "
                  >
                    <div
                      class="
                        d-flex
                        flex-row
                        align-items-center
                        justify-content-center
                        text-dark
                        main-text-container
                      "
                    >
                      <b-button
                        variant="outline-dark"
                        v-b-tooltip.hover.left
                        title="Copy Link"
                        class="
                          shadow-sm
                          ml-auto
                          border border-prime-gray
                          more-btn
                        "
                        pill
                        @click="copyPreviewLink(data)"
                      >
                        <font-awesome-icon
                          icon="link"
                          aria-label="Copy Link"
                          class="mx-auto more-icon"
                          size="sm"
                        ></font-awesome-icon>
                      </b-button>
                    </div>
                  </b-col>
                </template>
              </b-table>
              <b-row
                class="
                  d-flex
                  flex-row
                  align-items-center
                  justify-content-center justify-content-md-end
                  flex-shrink-0
                  px-3
                  py-2
                  w-100
                "
                no-gutters
              >
                <!-- pages per page control -->
                <b-col
                  class="
                    d-flex
                    flex-column
                    align-items-center
                    align-items-sm-start
                    align-items-md-end
                    align-items-xl-start
                    justify-content-center
                    py-2 py-md-1
                  "
                  cols="12"
                  sm="6"
                  lg="5"
                  xl="4"
                >
                  <b-form-group
                    label="Per page"
                    label-cols="12"
                    label-cols-sm="6"
                    label-align="left"
                    label-align-sm="right"
                    label-size="sm"
                    label-for="perPageSelect"
                    class="mb-0 w-100"
                  >
                    <b-form-select
                      v-model="perPage"
                      id="perPageSelect"
                      size="sm"
                      class="per-page-select border-1 py-0"
                      :options="pageOptions"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <!-- pagination controls -->
                <b-col
                  class="
                    d-flex
                    flex-column
                    align-items-center align-items-sm-end
                    justify-content-center
                    py-2 py-md-1
                  "
                  cols="12"
                  sm="6"
                  lg="4"
                  xl="3"
                >
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="right"
                    size="sm"
                    limit="4"
                    pills
                    class="d-none d-sm-flex table-pagination my-0"
                    page-class="custom-page-item"
                    ellipsis-class="custom-page-item"
                    first-class="custom-page-item"
                    last-class="custom-page-item"
                    next-class="custom-page-item"
                    prev-class="custom-page-item"
                  ></b-pagination>
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="center"
                    size="sm"
                    limit="5"
                    pills
                    class="d-flex d-sm-none table-pagination my-0"
                    page-class="custom-page-item"
                    ellipsis-class="custom-page-item"
                    first-class="custom-page-item"
                    last-class="custom-page-item"
                    next-class="custom-page-item"
                    prev-class="custom-page-item"
                  ></b-pagination>
                </b-col>
              </b-row>
            </b-col>
            <hr class="column-divider" />
            <div class="column-divider-line" />
          </b-row>
        </b-card>
      </b-row>
      <b-overlay :show="initLoading" no-wrap></b-overlay>
      <DemoVideoModal
        :isShow="isShowPreviewModal"
        @onClose="onClosePreviewVideoModal"
        :itemContent="previewVideoItem"
      />
    </b-container>
  </HomeLayout>
</template>

<script>
// @ is an alias to /src
import HomeLayout from "@/layout/HomeLayout";
import DemoVideoModal from "@/components/Videos/Modals/DemoVideoModal";
import States from "@/util/States";

// components
import FormInput from "@/components/Form/FormInput";
import FormSelect from "@/components/Form/FormSelect";
import FormButton from "@/components/Form/FormButton";
import FormInputGroup from "@/components/Form/FormInputGroup";

//services
import {
  CreateDemoAd,
  GetRenderStatus,
  GetDemoVideo,
} from "@/services/demoAd.service";

export default {
  name: "DemoAdVideo",
  components: {
    HomeLayout,
    FormInput,
    DemoVideoModal,
    FormButton,
    FormSelect,
    FormInputGroup,
  },
  data() {
    return {
      firstName: null,
      lastName: null,
      billingAddress: null,
      city: null,
      modalShow: false,
      previewVideoItem: null,
      isShowPreviewModal: false,
      isLoading: false,
      initLoading: false,
      isAPILoading: false,
      zipcode: null,
      state: null,
      firstNameError: {
        status: null,
        message: "",
      },
      lastNameError: {
        status: null,
        message: "",
      },
      billingAddressError: {
        status: null,
        message: "",
      },
      cityError: {
        status: null,
        message: "",
      },
      stateError: {
        status: null,
        message: "",
      },
      zipcodeError: {
        status: null,
        message: "",
      },
      stateList: [],
      uid: null,
      demoVideo: null,
      coverImage: null,
      isVideoReady: false,
      videoGenerating: false,
      HeaderFields: [
        {
          key: "indexNo",
          label: "#",
        },
        {
          key: "donor",
          label: "Donor",
        },
        {
          key: "createdAt",
          label: "Created At",
        },
        {
          key: "previewLink",
          label: "Preview",
        },
        {
          key: "linkIcon",
          label: "",
        },
      ],
      demoVideoList: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],
      getVideoPreviewLink: null,
      isTableLoading: false,
    };
  },
  mounted() {
    if (!this.$route.params.id) {
      this.$router.push({ name: "Videos" });
    }
    this.stateList = States.map((e) => e.name);
    this.getGeneratedVideo();
  },
  watch: {
    firstName(val) {
      if (val) {
        this.firstNameError.status = true;
      } else {
        this.firstNameError.status = false;
        this.firstNameError.message = "Please fill in your first name.";
      }
    },
    lastName(val) {
      if (val) {
        this.lastNameError.status = true;
      } else {
        this.lastNameError.status = false;
        this.lastNameError.message = "Please fill in your last name.";
      }
    },
    // billingAddress(val) {
    //   if (val) {
    //     this.billingAddressError.status = true;
    //   } else {
    //     this.billingAddressError.status = false;
    //     this.billingAddressError.message =
    //       "Please fill in your billing address";
    //   }
    // },
    city(val) {
      if (val) {
        this.cityError.status = true;
      } else {
        this.cityError.status = false;
        this.cityError.message = "Please fill in your city.";
      }
    },
    state(val) {
      if (val) {
        this.stateError.status = true;
      } else {
        this.stateError.status = false;
        this.stateError.message = "Please select your state.";
      }
    },
    // zipcode(val) {
    //   if (val) {
    //     this.zipcodeError.status = true;
    //   } else {
    //     this.zipcodeError.status = false;
    //     this.zipcodeError.message = "Please fill in your zip code.";
    //   }
    // },
  },
  computed: {},
  methods: {
    async videoCreateFn() {
      var errResult = this.errorFn();
      var id = this.$route.params.id;
      if (errResult.length == 0) {
        let payload = {
          firstName: this.firstName,
          lastName: this.lastName,
          // billingAddress: this.billingAddress,
          state: this.state,
          city: this.city,
          // zipcode: this.zipcode,
          target_video_id: id,
        };
        this.isAPILoading = true;
        try {
          let { data } = await CreateDemoAd(payload);
          if (data.code == 200 && data.message == "success") {
            // let payloadNotify = {
            //   isToast: true,
            //   title: "Demo Ad Creation",
            //   content: `The demo ad has been generated Successfully`,
            //   variant: "success",
            // };
            this.uid = data.content.uid;
            this.isVideoReady = false;
            this.videoGenerating = true;
            this.getRenderStatus();
            // this.$store.dispatch("notification/setNotify", payloadNotify);
          } else {
            let payloadNotify = {
              isToast: true,
              title: "Demo Ad Creation",
              content: "The demo ad cannot be created.",
              list: [data.details],
              variant: "danger",
            };
            this.$store.dispatch("notification/setNotify", payloadNotify);
          }
        } catch (error) {
          let payloadNotify = {
            isToast: true,
            title: "Demo Ad Creation",
            content: "The demo ad cannot be created.",
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        } finally {
          this.isAPILoading = false;
        }
      } else {
        this.handleSubmitError(errResult);
      }
    },
    async getRenderStatus() {
      GetRenderStatus(this.uid)
        .then((res) => {
          try {
            this.isVideoReady = true;
            this.videoGenerating = false;
            this.demoVideo = res.data.content.videoFileUrl;
            this.coverImage = res.data.content.targetVideoPreviewImage;
            this.demoVideoList = [res.data.content, ...this.demoVideoList];
            this.getVideoPreviewLink = res.data.content.resultVideoPreviewLink;
          } catch (error) {
            let payloadNotify = {
              isToast: true,
              title: "Demo Ad Creation",
              content: "Server error, Please try again",
              variant: "danger",
            };
            this.$store.dispatch("notification/setNotify", payloadNotify);
          }
        })
        .catch((res) => {
          try {
            this.isVideoReady = true;
            this.videoGenerating = false;
            this.demoVideo = res.data.content.videoFileUrl;
            this.coverImage = res.data.content.targetVideoPreviewImage;
            this.demoVideoList = [res.data.content, ...this.demoVideoList];
            this.getVideoPreviewLink = res.data.content.resultVideoPreviewLink;
          } catch (error) {
            let payloadNotify = {
              isToast: true,
              title: "Demo Ad Creation",
              content: "Server error, Please try again",
              variant: "danger",
            };
            this.$store.dispatch("notification/setNotify", payloadNotify);
          }

        });
    },
    async getGeneratedVideo() {
      try {
        var id = this.$route.params.id;
        this.isLoading = true;
        let { data } = await GetDemoVideo(id);
        if (data.code == 200 || data.message == "success") {
          this.demoVideoList = data.content.map((x, index) => {
            return {
              indexNo: this.getUniqueId(index),
              donor: {
                id: x._id,
                name: x.firstName + " " + x.lastName,
              },
              downloadLink: {
                id: x._id,
                value: x.resultVideoDownloadLink,
              },
              previewLink: {
                id: x._id,
                value: x.resultVideoPreviewLink,
              },
              createdAt: {
                value: x.created_at.split("T")[0],
              },
              linkIcon: x.resultVideoPreviewLink,
            };
          });
          this.totalRows = this.demoVideoList.length;
        } else {

          let payloadNotify = {
            isToast: true,
            title: "ERROR! Demo Videos",
            content: "Unable to retrieve Demo videos' data! Please try again.",
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
        this.isLoading = false;
      } catch (error) {
        console.log("ERROR DA01");
      } finally {
        this.isLoading = false;
      }
    },
    handleSubmitError(errDetails) {
      this.isAPILoading = false;
      this.apiConfirmationText = null;

      let payloadNotify = {
        isToast: true,
        title: "ERROR! Unable to create demo video",
        // content: errDetails ,
        content: !Array.isArray(errDetails) ? errDetails : null,
        list: Array.isArray(errDetails) ? errDetails : null,
        variant: "danger",
      };

      this.$store.dispatch("notification/setNotify", payloadNotify);
    },
    errorFn() {
      var errorList = [];
      if (!this.firstName) {
        this.firstNameError.status = false;
        this.firstNameError.message = "Please fill in your first name.";
        errorList.push("Please fill in your first name.");
      }
      if (!this.lastName) {
        this.lastNameError.status = false;
        this.lastNameError.message = "Please fill in your last name.";
        errorList.push("Please fill in your last name.");
      }
      if (!this.city) {
        this.cityError.status = false;
        this.cityError.message = "Please fill in city.";
        errorList.push("Please fill in city.");
      }
      if (!this.state) {
        this.stateError.status = false;
        this.stateError.message = "Please select your state.";
        errorList.push("Please select your state.");
      }
      // if (!this.billingAddress) {
      //   this.billingAddressError.status = false;
      //   this.billingAddressError.message =
      //     "Please fill in your billing address.";
      //   errorList.push("Please fill in your billing address.");
      // }
      // if (!this.zipcode) {
      //   this.zipcodeError.status = false;
      //   this.zipcodeError.message = "Please fill in your zip code.";
      //   errorList.push("Please fill in your zip code.");
      // }
      return errorList;
    },
    // preview modal functions
    openPreviewVideoModal(item, button) {
      ("preview", item);
      this.isShowPreviewModal = true;
      this.previewVideoItem = item;
      this.$root.$emit("bv::show::modal", "demo-video-row-modal", button);
    },
    onClosePreviewVideoModal() {
      this.isShowPreviewModal = false;
    },
    copyLink(elementId) {
      try {
        let copyInputElement = document.getElementById(elementId);
        copyInputElement.setAttribute("type", "text");
        copyInputElement.select();
        document.execCommand("copy");
        let payloadNotify = {
          isToast: true,
          title: "Video Preview Link",
          content: "Link was copied successfully.",
          variant: "success",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      } catch (err) {
        let payloadNotify = {
          isToast: true,
          title: "Video Preview Link",
          content: "Unabe to copy vidoe preview link to the clipboard.",
          list: [err.message],
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
    },
    copyPreviewLink(val) {
      try {
        navigator.clipboard.writeText(val.value);
        let payloadNotify = {
          isToast: true,
          title: "Video Preview Link",
          content: "Link was copied successfully.",
          variant: "success",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      } catch (err) {
        let payloadNotify = {
          isToast: true,
          title: "Video Preview Link",
          content: "Unabe to copy vidoe preview link to the clipboard.",
          list: [err.message],
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
    },
    getUniqueId(index) {
      // minus 1 from it to start from 0
      let currentPos = this.currentPage - 1;
      let position = currentPos * this.perPage;
      let newIndex = index + 1;
      let newPos = position + newIndex;
      return `${String(newPos).padStart(2, "0")}`;
    },
  },
};
</script>

<style scoped>
/* container card */
.card-row {
  height: 100%;
}

.card-row .card-container {
  background-color: var(--white);
  width: 100%;
  min-height: 560px;
  border-radius: 16px 16px 0px 0px;
  border: 1px solid var(--light);
}

.form-file {
  color: var(--prime-gray);
  border: violet;
}

/* placeholder text color */
.form-file >>> .form-control::placeholder {
  color: var(--prime-gray);
}

/* custom checkbox */
.custom-checkbox,
.custom-checkbox-active {
  cursor: pointer;
}

/* checkbox color (default state) */
.custom-checkbox >>> .custom-control-label,
.custom-checkbox-active >>> .custom-control-label {
  user-select: none;
}

.custom-checkbox >>> .custom-control-label::before {
  background-color: var(--ghost-white);
  border-color: transparent;
}

.custom-checkbox >>> .custom-control-label::after {
  background-color: var(--white);
}

/* override default bootstrap styles */
.custom-checkbox
  >>> .custom-control-input:not(:disabled):active
  ~ .custom-control-label::before {
  background-color: var(--info);
  border-color: transparent;
}

.custom-checkbox
  >>> .custom-control-input:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: var(--info);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 0 0.2rem rgba(20, 160, 190, 0.25);
}

/* checkbox color (active state) */
.custom-checkbox-active >>> .custom-control-label::before {
  background-color: var(--info);
  border-color: transparent;
}

/* override default bootstrap styles */
.custom-checkbox-active
  >>> .custom-control-input:not(:disabled):active
  ~ .custom-control-label::before {
  background-color: var(--info);
  border-color: transparent;
}

.custom-checkbox-active
  >>> .custom-control-input:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: var(--info);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 0 0.2rem rgba(20, 160, 190, 0.25);
}

.custom-checkbox-active
  >>> .custom-control-input:focus
  ~ .custom-control-label::before {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 0 0.2rem rgba(20, 160, 190, 0.25);
}

.video-preview-row {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}

.video-preview-row .video-placeholder {
  min-height: 300px;
}
.video-container-row {
  overflow: hidden;
}
/* video preview image */
.video-container-row .video-preview-image {
  width: 100%;
  height: 280px;
  border-radius: 3px;
  outline: none;
  border: none;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
  object-fit: cover;
  filter: brightness(0.6);
}
/* video preview button */
.video-container-row .video-preview-button {
  position: absolute;
  width: 50px;
  height: 50px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 38px;
  box-shadow: 0px 2px 8px var(--shadow-soft);
  border: 2px solid var(--white);
  padding: 0 0 0 3px;
  color: var(--white);
  background-color: transparent;
  transition: color, background-color 200ms ease-in;
}
.video-container-row .video-preview-button .play-icon {
  width: 24px;
  height: 24px;
}
/* video preview button (hover state) */
.video-container-row .video-preview-button:hover {
  color: var(--dark);
  background-color: var(--white);
}
.headings {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  /* font-weight: bolder; */
}
.video-card-row {
  height: auto;
  min-height: 380px;
}
.video-card-row .video-card-container {
  width: 100%;
  min-height: 380px;
  background-color: var(--white);
  border-radius: 0 0 16px 16px;
  border: 1px solid rgba(135, 147, 163, 0.25);
}
.body-container {
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}
.body-container-cards {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  /* grid-gap: 10px;
  padding: 10px; */
}
.body-container::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}
.body-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}
.body-container::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}
.body-container .column-divider {
  width: 100%;
  height: 100%;
  border-top: none;
  border-left: 1px solid var(--light);
}
.body-container .first-column-divider {
  width: 753px;
  height: 100%;
  border-top: none;
  border-left: 2px solid var(--light);
}
.table-element >>> .table-header th {
  color: rgba(135, 147, 163, 1);
  font-size: 0.9rem;
  font-weight: 400;
  border-color: transparent;
  font-family: "Roboto", sans-serif;
}
.table-element >>> .table-body td {
  padding: 10px;
}
.table-element >>> .table-body .main-text-container {
  max-width: 400px;
}
.table-element >>> .table-body .main-text-container span {
  display: inline-block;
}
.more-btn {
  width: 35px;
  height: 35px;
  /* background-color: var(--primary); */
  padding: 5px;
  opacity: 1;
  transition: opacity 200ms ease-in;
}
/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .video-preview-row {
    padding: 1rem 0;
  }
}
</style>
