import Instance from "./instance";

export const CreateDemoAd = async (payload) => {
  try {
    return await Instance.instanceToken.post(`/demo_donors/`, payload);
  } catch (error) {
    return error;
  }
};

export const GetRenderStatus = async (id) => {
  try {
    return await Instance.instanceToken.get(`demo_donors/renderStatus/${id}`);
  } catch (error) {
    return error;
  }
};


export const GetDemoVideo = async (id) => {
  try {
    return await Instance.instanceToken.get(`demo_donors`, {
      params: {
        target_video: id
      }
    });
  } catch (error) {
    return error;
  }
};